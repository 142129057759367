$file-uploader-font: 'montserrat-medium';
$font-awesome: "FontAwesome";

.media-manager {
  padding: 40px;

  .dt-search {
    display: table;
    float: right;
    padding: 20px 0 20px 20px;

    .dt-search-icon {
      display: block;
      height: 20px;
      width: 20px;
      background-color: blue;
      float: right;
    }

    #files-table_filter.dataTables_filter {
      padding: 0;
      float: left;
    }
  }

  .breadcrums {
    float: left;
    display: block;
    width: 100%;

    a.btn {
      display: inline-block;
      font-family: $file-uploader-font;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: 500;
      color: #000000;
    }

    i {
      font-size: 16px;
      font-size: 1.6rem;
      color: #000000;
      vertical-align: middle;
    }
  }

  .multi-items-controls {
    float: none;
    margin: 10px 0px 10px auto;
    display: table;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    a {
      padding: 19px 20px 20px;
      font-family: $file-uploader-font;
      font-size: 16px;
      font-size: 1.6rem;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0;
      float: left;
    }

    #selectAll {
      background-image: none;
      border: none;
      background-color: #2036a3;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      .deselect {
        display: none;
      }

      &.active {
        background-color: #15236b;

        .select {
          display: none;
        }

        .deselect {
          display: inline-block;
        }
      }
    }

    #downloadAll {
      background-image: none;
      border: none;
      background-color: #2036a3;
      border-left: 1px solid white;
      border-right: 1px solid white;
    }

    #deleteAll {
      background-image: none;
      border: none;
      background-color: #2036a3;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .item.is-parent i {
    margin-right: 5px;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }

  .first-row {
    padding: 10px 0;
    border: solid 1px #2036a3;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    .left a {
      font-family: $file-uploader-font;
      font-size: 16px;
      font-size: 1.6rem;
      font-weight: 500;
      text-align: center;
      color: #2036a3;

      i {
        margin-right: 10px;
      }
    }

    .second-row .filter-by-tag {
      display: inline-block;

      label {
        font-family: $file-uploader-font;
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: 500;
        color: #000000;
      }

      select {
        width: 160px;
        padding: 9px 0 9px 5px;
        border-radius: 5px;
        border: solid 1px #666666;
        font-family: $file-uploader-font;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #000000;
        outline: none;

        /* Remove default arrow for Firefox */
        -moz-appearance: none;

        /* Remove default arrow for Chrome */
        -webkit-appearance: none;

        /* Remove default arrow for IE */
        /* SVG background image */
        background-image: url('./styles/svg/Chevrones.svg');
        background-size: 2.5rem;
        background-position: calc(100% - 5px) center;
        background-repeat: no-repeat;

        &::-ms-expand {
          display: none;
        }
      }
    }

    .right form .form-group {
      label {
        font-family: $file-uploader-font;
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: 500;
        text-align: center;
        color: #000000;
      }

      input {
        display: inline-block;
        font-family: $file-uploader-font;
        width: 300px;
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: 500;
        color: #9e9e9e;
        height: 40px;
        border-radius: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      button {
        float: none;
        vertical-align: baseline;
        display: inline-block;
        width: auto;
        padding: 9px 13px 9px 12px;
        background-color: #2036a3;
        background-image: none;
        margin-left: -4px;
        border-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .item-actions {
    a.btn {
      display: block;
      float: right;
      margin-right: 5px;
      background-color: inherit;
      background-image: none;
      border: none;
      color: black;
      box-shadow: none;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }

    &:after {
      content: "";
      clear: both;
      display: table;
      height: 0;
    }
  }

  #files-table_wrapper {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: solid 1px #2036a3;

    .dataTables_length {
      padding: 20px 0 20px 20px;

      label {
        font-family: $file-uploader-font;
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: 500;
        color: #000000;

        select {
          border-radius: 5px;
          border: solid 1px #666666;
          padding: 4px;
          text-align: left;

          /* Remove default arrow for Firefox */
          -moz-appearance: none;

          /* Remove default arrow for Chrome */
          -webkit-appearance: none;

          /* Remove default arrow for IE */
          /* SVG background image */
          width: 60px;
          height: auto;
          background-image: url(svg/Chevrones.svg);
          background-size: 2.5rem;
          background-position: calc(100% - 5px) center;
          background-repeat: no-repeat;

          &::-ms-expand {
            display: none;
          }
        }
      }
    }

    .dt-search {
      padding: 20px;

      .dt-search-icon {
        position: relative;
        width: 40px;
        height: 40px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-left: -2px;
        background-color: #2036a3;
        cursor: pointer;

        &:after {
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          font-family: $font-awesome;
          content: "\f002";
          color: white;
        }
      }

      .dataTables_filter {
        font-family: $file-uploader-font;
        font-size: 14px;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: center;
        color: #9e9e9e;

        input {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border: solid 1px #666666;
          padding: 9px 3px;
          width: 400px;
          outline: none;
        }
      }
    }

    #files-table {
      thead tr {
        background-color: #2036a3;
        font-family: $file-uploader-font;
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: 500;
        color: #ffffff;

        th {
          padding: 19px 20px;

          &:nth-child(2) {
            background-image: none;
            cursor: default;
          }

          &:nth-child(3) {
            background-image: none;
            cursor: default;
            color: #2036a3;
          }
        }
      }

      tbody tr {

        td {
          padding: 20px;

          a.btn {
            &.select {
              padding: 0;
              margin-right: 20px;
              font-size: 18px;
              font-size: 1.8rem;

              i {
                -webkit-transform: scale(1.5);
                -moz-transform: scale(1.5);
                -ms-transform: scale(1.5);
                -o-transform: scale(1.5);
                transform: scale(1.5);
              }
            }

            &.folder {
              font-size: 16px;
              font-size: 1.6rem;
              font-weight: 600;
              color: #000000;
              padding: 0;
              white-space: normal;
              word-wrap: break-word;
            }
          }
        }

        &.odd {
          background-color: #e4e6f1;

          td {
            background-color: #e4e6f1;
          }
        }

        &.even {
          background-color: #ffffff;

          td {
            background-color: #ffffff;
          }
        }

      }
    }

    .dataTables_info {
      padding: 20px;
      font-family: $file-uploader-font;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }

    .dataTables_paginate {
      padding: 20px;

      .paginate_button {
        padding: 0;
      }

      span {
        margin: 0 5px;

        a.paginate_button {
          border: solid 1px #979797;
          background: none;
          margin: 0;
          padding: 0;
          background-color: #ffffff;

          &.current {
            background-color: #e4e6f1;

            &:hover {
              background-color: #e4e6f1;
            }
          }

          &:hover {
            background-color: #ffffff;
            color: black !important;
          }
        }
      }
    }
  }

}

body.is-root #file-uploader .media-manager {
  .first-row .left {
    display: none;
  }

  #files-table_wrapper #files-table thead tr th:nth-child(2) {
    color: #2036a3;
  }
}

@media (max-width: 991px) {
  .media-manager {
    .first-row .right form .form-group input {
      width: 300px;
    }

    #files-table_wrapper {
      overflow: auto;

      .dt-search {
        padding: 0;
        float: none;

        .dt-search-icon {
          margin-top: 20px;
        }

        #files-table_filter label input {
          width: 300px;
          margin-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .media-manager {
    padding: 0;

    .multi-items-controls {
      float: none;

      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }

    .first-row {
      .left a {
        word-break: break-all;
        white-space: normal;
      }

      .right form .form-group input {
        width: 140px;
      }
    }

    #files-table_wrapper {
      overflow: auto;

      .dt-search {
        padding: 0 20px 0 0;
        float: right;

        #files-table_filter label input {
          width: 225px;
          margin-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .media-manager #files-table_wrapper .dt-search {
    #files-table_filter label input {
      width: 160px;
      margin-top: 20px;
    }

    .dt-search-icon {
      margin: 27px 0 0 0;
    }

    padding: 0 20px 0 0;
    float: none;
    display: table;
    margin: 0 auto;
  }
}
