$file-uploader-font: 'montserrat-medium';
$file-uploader-font-bold: 'montserrat-bold';

main {
  .current-page {
    h1.t-h1 {
      font-family: $file-uploader-font;
      color: #cc6045;
      font-size: 32px;
      font-size: 3.2rem;
      display: inline-block;
    }

    *+.line {
      margin-top: 4px;
    }

    .line {
      height: 4px;
      max-width: 474px;
      background-color: #1c2240;
      -webkit-transform: translateX(-166px);
      -moz-transform: translateX(-166px);
      -ms-transform: translateX(-166px);
      -o-transform: translateX(-166px);
      transform: translateX(-166px);
    }

    +* {
      margin-top: 78px;
    }
  }
}

#dropzone-form {
  font-family: $file-uploader-font;
  max-width: none;
  padding: 126px 234px;
  border-radius: 5px;
  border: dashed 2px #2036a3;
  background-color: #f9f9f9;

  .fake-btn {
    display: inline-block;
    padding: 13px 54px;
    border-radius: 5px;
    border: solid 1px #2036a3;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 500;
    color: #2036a3;
  }

  .file-msg {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 500;
    color: #2036a3;
  }

  &:hover .fake-btn {
    background-color: #2036a3;
    color: #ffffff;
  }

  +* {
    margin-top: 40px;
  }
}

.global-tags-wrapper {
  label {
    font-family: $file-uploader-font-bold;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    color: #000000;
  }

  input {
    font-family: $file-uploader-font;
    padding: 10px;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 500;
    color: #9e9e9e;
    height: auto;
  }

  #applyTags {
    font-family: $file-uploader-font;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    background-color: #2036a3;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 500;
    color: #ffffff;
    border: none;
    margin-right: 0;
    background-image: none;
    width: 160px;
    height: 40px;

    &:hover {
      background-color: #15236b;
    }
  }
}

.form-group select {
  display: inline;
}

.form-group.select-folder {
  margin-bottom: 0;

  label {
    font-family: $file-uploader-font-bold;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    color: #000000;
  }

  select {
    font-family: $file-uploader-font;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: normal;
    color: #000000;
    display: block;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    border: solid 1px #666666;
    margin-top: 20px;
    padding: 6px 0 6px 8px;

    /* Remove default arrow for Firefox */
    -moz-appearance: none;

    /* Remove default arrow for Chrome */
    -webkit-appearance: none;

    /* Remove default arrow for IE */
    /* SVG background image */
    width: 100%;
    height: 40px;
    background-image: url(svg/Chevrones.svg);
    background-size: 2.5rem;
    background-position: 129px center;
    background-position: calc(100% - 5px) center;
    background-repeat: no-repeat;

    &::-ms-expand {
      display: none;
    }
  }
}

#create-folder {
  margin: 40px 0 0 0;

  +* {
    margin-top: 70px;
  }

  .content {
    label {
      font-family: $file-uploader-font-bold;
      margin: 0;
      font-size: 18px;
      font-size: 1.8rem;
      font-weight: bold;
      display: block;
      color: #000000;

      .optional {
        font-size: 15px;
        font-size: 1.5rem;
        color: #9e9e9e;
        margin-left: 10px;
      }
    }

    *+* {
      margin-top: 20px;
    }

    input#folder {
      font-family: $file-uploader-font;
      padding: 10px;
      font-size: 16px;
      font-size: 1.6rem;
      font-weight: 500;
      color: #9e9e9e;
      height: 40px;
      width: 70%;
      display: inline-block;
      border: 1px solid black;
    }

    button.btn {
      font-family: $file-uploader-font;
      border-radius: 5px;
      background-color: white;
      background-image: none;
      font-size: 18px;
      font-size: 1.8rem;
      font-weight: 500;
      color: #2036a3;
      height: 40px;
      width: 160px;
      margin-left: 30px;
      border-color: #2036a3;
      float: right;

      &:hover {
        background-color: #2036a3;
        color: white;
        border-style: none;
      }
    }
  }

  *+.submit-folder {
    margin-top: 30px;
  }

}

.action-buttons {
  padding: 20px;
  display: table;
  width: 100%;

  a.clear-completed {
    font-family: $file-uploader-font;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    background-color: #d3cf00;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 500;
    color: #ffffff;
    border: none;
    margin-right: 0;
    background-image: none;
    height: 40px;
    width: initial;

    &:hover {
      background-color: #15236b;
    }
  }
}

*+ {
  .form-group.select-folder {
    margin-top: 30px;
  }

  #btnUpload {
    margin-top: 40px;
  }

  .input_fields_wrapper {
    margin-top: 30px;
  }
}

#btnUpload {
  display: none;
  font-family: $file-uploader-font;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background-color: #2036a3;
  padding: 13px 58px;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #ffffff;
  border: none;
  margin-right: 0;
  background-image: none;

  &:hover {
    background-color: #15236b;
  }
}

*+.filter-tags {
  margin-top: 41px;
}

.hr-block {
  display: none;
  margin: 70px auto;
  width: 100%;
  height: 2px;
  background-color: #cc6045;
}

a.select-all {
  font-family: $file-uploader-font;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background-color: #2036a3;
  height: 40px;
  width: 190px;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #ffffff;
  border: none;
  margin-right: 0;
  background-image: none;
  text-decoration: none;
  line-height: 40px;
  text-align: center;
  margin-bottom: 20px;
  float: right;

  i {
    margin-left: -20px;
    margin-right: 10px;
  }

  &:hover {
    background-color: #15236b;
  }
}

.filter-tags {
  .title .t-h2 {
    font-family: $file-uploader-font;
    font-size: 24px;
    font-size: 2.4rem;
    font-weight: 500;
    color: #cc6045;
  }

  *+.filter-bar-container {
    margin-top: 31px;
  }

  #filter-by-tag {
    .content {
      margin-bottom: 0;

      label {
        font-family: $file-uploader-font-bold;
        font-size: 18px;
        font-size: 1.8rem;
        font-weight: bold;
        color: #000000;
      }

      select {
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: normal;
        color: #000000;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border-radius: 5px;
        border: solid 1px #666666;
        padding: 6px 0 6px 8px;

        /* Remove default arrow for Firefox */
        -moz-appearance: none;

        /* Remove default arrow for Chrome */
        -webkit-appearance: none;

        /* Remove default arrow for IE */
        /* SVG background image */
        width: 160px;
        height: auto;
        background-image: url(svg/Chevrones.svg);
        background-size: 2.5rem;
        background-position: 129px center;
        background-position: calc(100% - 5px) center;
        background-repeat: no-repeat;

        &::-ms-expand {
          display: none;
        }
      }
    }

    *+.btn-upload {
      margin-top: 43px;
    }

    .btn-upload {
      margin-bottom: 0;

      button {
        font-family: $file-uploader-font;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border-radius: 5px;
        background-color: #2036a3;
        padding: 13px 58px;
        font-size: 18px;
        font-size: 1.8rem;
        font-weight: 500;
        color: #ffffff;
        border: none;
        margin-right: 0;
        background-image: none;

        &:hover {
          background-color: #15236b;
        }
      }
    }
  }
}

*+.files-container {
  margin-top: 32px;
}

.files-container {
  width: 100%;
  height: 500px;
  overflow: auto;
  margin: 0 auto;
  background-color: #fbfbfb;
  padding: 18px 5px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border: 0px none #ffffff;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-track {
    background: #efefef;
    border: 0px none #ffffff;
    border-radius: 0px;
  }

  .files-wrapper {
    &:after {
      content: '';
      display: table;
      clear: both;
    }

    .file-item-container {
      margin-bottom: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      max-width: 20%;
      width: 100%;
      float: left;
      padding: 0 7px;

      .file-item-wrapper {
        height: 100%;
        width: 100%;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border-radius: 5px;
        border: solid 1px #2036a3;
        background-color: #ffffff;

        a {
          display: table;
          width: inherit;
          text-decoration: none;

          &:link,
          &:active,
          &:hover,
          &:visited {
            text-decoration: none;
          }

          strong {
            color: black;
          }

          p {
            font-size: 12px;
            font-size: 1.2rem;
            color: black;
          }
        }

        .file-item-image {
          width: auto;
          text-align: center;
          padding: 15px 70px 11px 70px;
          font-size: 20px;
          font-size: 2rem;
          color: #2036a3;
        }

        .file-item-description {
          width: 100%;
          padding: 5px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;

          .title {
            font-family: $file-uploader-font;
            font-size: 16px;
            font-size: 1.6rem;
            font-weight: 500;
            text-align: center;
            color: #000000;
            word-break: break-all;
            white-space: normal;
          }

          .description {
            font-size: 12px;
            font-size: 1.2rem;
            color: #000000;
            text-align: center;
            word-break: break-all;
            white-space: normal;
          }
        }
      }
    }
  }
}

.clear-filters {
  display: none;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 20px;
}

.input_fields_wrapper {
  .file-tags {
    display: inline;
  }

  .item {
    border: 1.5px solid #2036a3;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    &.pending.uploaded {
      .file-label {
        display: inline;
      }

      .file {
        display: inline;
        margin-left: 10px;
      }
    }

    &.done {
      background-color: #5ac784;
    }
  }

  .select2.select2-container {
    display: inline-block;
    width: 444px !important;
    margin-left: 10px;

    .select2-selection.select2-selection--multiple {
      width: 444px;
      display: inline-block;
    }

    .file-item-wrapper {
      margin-bottom: 10px;

      input[type=text] {
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border-radius: 5px;
        border: solid 1px #666666;
        height: 40px;
        font-size: 16px;
        font-size: 1.6rem;
        padding: 0 5px;
        margin-left: 10px;
        width: 75%;
      }

      .file-label,
      .tags-label {
        font-size: 16px;
        font-size: 1.6rem;
        font-family: $file-uploader-font-bold;
      }
    }

    *+.file-item-wrapper {
      margin-bottom: 20px;
    }

    .select-wrapper {
      float: right;
      margin-top: 10px;
    }
  }

  .tags-wrapper {
    width: 100%;
  }
}

.nav-tabs {
  border-bottom: none;
}

.tab-content {
  -webkit-border-radius: 0px 5px 5px 5px;
  -moz-border-radius: 0px 5px 5px 5px;
  -ms-border-radius: 0px 5px 5px 5px;
  -o-border-radius: 0px 5px 5px 5px;
  border-radius: 0px 5px 5px 5px;
  border-top-left-radius: 0px;
  border: solid 1px #2036a3;

  .tab-pane {
    padding: 40px;
  }
}

*+footer {
  margin-top: 70px;
}

@media (max-width: 991px) {
  .input_fields_wrapper .select2.select2-container {
    display: block;
    width: 444px !important;
    margin: 10px 0 0 0 !important;
  }

  .filter-tags .files-container .files-wrapper .file-item-container {
    max-width: 33.3333%;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .progress-status {
    width: 444px;
  }

  #dropzone-form {
    padding: 126px 50px;

    .fake-btn {
      padding: 10px 10px;
    }

    .file-msg {
      word-break: break-all;
      white-space: normal;
    }
  }

  #create-folder .content button.btn {
    float: none;
    margin-left: 0;
    display: block;
  }

  .input_fields_wrapper {
    .item input.keywords {
      display: block;
      margin: 10px 0 0 0 !important;
      width: 444px !important;
    }

    .select2.select2-container {
      display: block;
      width: 444px !important;
      margin: 10px 0 0 0 !important;
    }
  }

  .filter-tags .files-container .files-wrapper .file-item-container {
    max-width: none;
    width: 100%;
    float: none;
  }
}

@media (max-width: 660px) {
  .global-tags-wrapper {
    a.select-all {
      float: none;
      display: block;
      margin: 0 auto 20px auto;
      width: 100%;
    }

    .tags-title {
      text-align: center;
      margin: 0 auto 20px 0;
      display: block;
    }

    .global-tags {
      width: 100%;
      margin: 0 auto;
    }

    a#applyTags {
      width: 100% !important;
      height: 40px;
      margin: 20px auto;
      display: block;
    }
  }

  .action-buttons a.clear-completed {
    width: 100% !important;
    height: 40px;
    margin: 20px auto;
    display: block;
  }

  .item {
    .progress-status {
      width: inherit;
    }

    .file {
      display: block !important;
      margin-top: 10px !important;
      margin-left: 0 !important;
    }
  }

  .input_fields_wrapper {
    .select2.select2-container {
      .select2-selection.select2-selection--multiple {
        width: 100%;
        display: block;
      }

      display: block;
      width: 100% !important;
      margin: 10px 0 0 0 !important;
    }

    .item .file-item-wrapper input[type=text] {
      width: 100% !important;
    }
  }

  .btnUploader-wrapper {
    display: block;
    margin: 0 auto;
    margin-top: 70px;

    a {
      margin: 0 auto !important;
    }
  }
}
