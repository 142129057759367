main .current-page h1.t-h1 {
  font-family: "montserrat-medium";
  color: #cc6045;
  font-size: 32px;
  font-size: 3.2rem;
  display: inline-block;
}
main .current-page * + .line {
  margin-top: 4px;
}
main .current-page .line {
  height: 4px;
  max-width: 474px;
  background-color: #1c2240;
  -webkit-transform: translateX(-166px);
  -moz-transform: translateX(-166px);
  -ms-transform: translateX(-166px);
  -o-transform: translateX(-166px);
  transform: translateX(-166px);
}
main .current-page + * {
  margin-top: 78px;
}

#dropzone-form {
  font-family: "montserrat-medium";
  max-width: none;
  padding: 126px 234px;
  border-radius: 5px;
  border: dashed 2px #2036a3;
  background-color: #f9f9f9;
}
#dropzone-form .fake-btn {
  display: inline-block;
  padding: 13px 54px;
  border-radius: 5px;
  border: solid 1px #2036a3;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #2036a3;
}
#dropzone-form .file-msg {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #2036a3;
}
#dropzone-form:hover .fake-btn {
  background-color: #2036a3;
  color: #ffffff;
}
#dropzone-form + * {
  margin-top: 40px;
}

.global-tags-wrapper label {
  font-family: "montserrat-bold";
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #000000;
}
.global-tags-wrapper input {
  font-family: "montserrat-medium";
  padding: 10px;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #9e9e9e;
  height: auto;
}
.global-tags-wrapper #applyTags {
  font-family: "montserrat-medium";
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background-color: #2036a3;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #ffffff;
  border: none;
  margin-right: 0;
  background-image: none;
  width: 160px;
  height: 40px;
}
.global-tags-wrapper #applyTags:hover {
  background-color: #15236b;
}

.form-group select {
  display: inline;
}

.form-group.select-folder {
  margin-bottom: 0;
}
.form-group.select-folder label {
  font-family: "montserrat-bold";
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #000000;
}
.form-group.select-folder select {
  font-family: "montserrat-medium";
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: normal;
  color: #000000;
  display: block;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border: solid 1px #666666;
  margin-top: 20px;
  padding: 6px 0 6px 8px;
  /* Remove default arrow for Firefox */
  -moz-appearance: none;
  /* Remove default arrow for Chrome */
  -webkit-appearance: none;
  /* Remove default arrow for IE */
  /* SVG background image */
  width: 100%;
  height: 40px;
  background-image: url(svg/Chevrones.svg);
  background-size: 2.5rem;
  background-position: 129px center;
  background-position: calc(100% - 5px) center;
  background-repeat: no-repeat;
}
.form-group.select-folder select::-ms-expand {
  display: none;
}

#create-folder {
  margin: 40px 0 0 0;
}
#create-folder + * {
  margin-top: 70px;
}
#create-folder .content label {
  font-family: "montserrat-bold";
  margin: 0;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  display: block;
  color: #000000;
}
#create-folder .content label .optional {
  font-size: 15px;
  font-size: 1.5rem;
  color: #9e9e9e;
  margin-left: 10px;
}
#create-folder .content * + * {
  margin-top: 20px;
}
#create-folder .content input#folder {
  font-family: "montserrat-medium";
  padding: 10px;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #9e9e9e;
  height: 40px;
  width: 70%;
  display: inline-block;
  border: 1px solid black;
}
#create-folder .content button.btn {
  font-family: "montserrat-medium";
  border-radius: 5px;
  background-color: white;
  background-image: none;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #2036a3;
  height: 40px;
  width: 160px;
  margin-left: 30px;
  border-color: #2036a3;
  float: right;
}
#create-folder .content button.btn:hover {
  background-color: #2036a3;
  color: white;
  border-style: none;
}
#create-folder * + .submit-folder {
  margin-top: 30px;
}

.action-buttons {
  padding: 20px;
  display: table;
  width: 100%;
}
.action-buttons a.clear-completed {
  font-family: "montserrat-medium";
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background-color: #d3cf00;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #ffffff;
  border: none;
  margin-right: 0;
  background-image: none;
  height: 40px;
  width: initial;
}
.action-buttons a.clear-completed:hover {
  background-color: #15236b;
}

* + .form-group.select-folder {
  margin-top: 30px;
}
* + #btnUpload {
  margin-top: 40px;
}
* + .input_fields_wrapper {
  margin-top: 30px;
}

#btnUpload {
  display: none;
  font-family: "montserrat-medium";
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background-color: #2036a3;
  padding: 13px 58px;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #ffffff;
  border: none;
  margin-right: 0;
  background-image: none;
}
#btnUpload:hover {
  background-color: #15236b;
}

* + .filter-tags {
  margin-top: 41px;
}

.hr-block {
  display: none;
  margin: 70px auto;
  width: 100%;
  height: 2px;
  background-color: #cc6045;
}

a.select-all {
  font-family: "montserrat-medium";
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background-color: #2036a3;
  height: 40px;
  width: 190px;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #ffffff;
  border: none;
  margin-right: 0;
  background-image: none;
  text-decoration: none;
  line-height: 40px;
  text-align: center;
  margin-bottom: 20px;
  float: right;
}
a.select-all i {
  margin-left: -20px;
  margin-right: 10px;
}
a.select-all:hover {
  background-color: #15236b;
}

.filter-tags .title .t-h2 {
  font-family: "montserrat-medium";
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 500;
  color: #cc6045;
}
.filter-tags * + .filter-bar-container {
  margin-top: 31px;
}
.filter-tags #filter-by-tag .content {
  margin-bottom: 0;
}
.filter-tags #filter-by-tag .content label {
  font-family: "montserrat-bold";
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #000000;
}
.filter-tags #filter-by-tag .content select {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: normal;
  color: #000000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border: solid 1px #666666;
  padding: 6px 0 6px 8px;
  /* Remove default arrow for Firefox */
  -moz-appearance: none;
  /* Remove default arrow for Chrome */
  -webkit-appearance: none;
  /* Remove default arrow for IE */
  /* SVG background image */
  width: 160px;
  height: auto;
  background-image: url(svg/Chevrones.svg);
  background-size: 2.5rem;
  background-position: 129px center;
  background-position: calc(100% - 5px) center;
  background-repeat: no-repeat;
}
.filter-tags #filter-by-tag .content select::-ms-expand {
  display: none;
}
.filter-tags #filter-by-tag * + .btn-upload {
  margin-top: 43px;
}
.filter-tags #filter-by-tag .btn-upload {
  margin-bottom: 0;
}
.filter-tags #filter-by-tag .btn-upload button {
  font-family: "montserrat-medium";
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background-color: #2036a3;
  padding: 13px 58px;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #ffffff;
  border: none;
  margin-right: 0;
  background-image: none;
}
.filter-tags #filter-by-tag .btn-upload button:hover {
  background-color: #15236b;
}

* + .files-container {
  margin-top: 32px;
}

.files-container {
  width: 100%;
  height: 500px;
  overflow: auto;
  margin: 0 auto;
  background-color: #fbfbfb;
  padding: 18px 5px;
}
.files-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.files-container::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.files-container::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border: 0px none #ffffff;
  border-radius: 0px;
}
.files-container::-webkit-scrollbar-track {
  background: #efefef;
  border: 0px none #ffffff;
  border-radius: 0px;
}
.files-container .files-wrapper:after {
  content: "";
  display: table;
  clear: both;
}
.files-container .files-wrapper .file-item-container {
  margin-bottom: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  max-width: 20%;
  width: 100%;
  float: left;
  padding: 0 7px;
}
.files-container .files-wrapper .file-item-container .file-item-wrapper {
  height: 100%;
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border: solid 1px #2036a3;
  background-color: #ffffff;
}
.files-container .files-wrapper .file-item-container .file-item-wrapper a {
  display: table;
  width: inherit;
  text-decoration: none;
}
.files-container .files-wrapper .file-item-container .file-item-wrapper a:link, .files-container .files-wrapper .file-item-container .file-item-wrapper a:active, .files-container .files-wrapper .file-item-container .file-item-wrapper a:hover, .files-container .files-wrapper .file-item-container .file-item-wrapper a:visited {
  text-decoration: none;
}
.files-container .files-wrapper .file-item-container .file-item-wrapper a strong {
  color: black;
}
.files-container .files-wrapper .file-item-container .file-item-wrapper a p {
  font-size: 12px;
  font-size: 1.2rem;
  color: black;
}
.files-container .files-wrapper .file-item-container .file-item-wrapper .file-item-image {
  width: auto;
  text-align: center;
  padding: 15px 70px 11px 70px;
  font-size: 20px;
  font-size: 2rem;
  color: #2036a3;
}
.files-container .files-wrapper .file-item-container .file-item-wrapper .file-item-description {
  width: 100%;
  padding: 5px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.files-container .files-wrapper .file-item-container .file-item-wrapper .file-item-description .title {
  font-family: "montserrat-medium";
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: #000000;
  word-break: break-all;
  white-space: normal;
}
.files-container .files-wrapper .file-item-container .file-item-wrapper .file-item-description .description {
  font-size: 12px;
  font-size: 1.2rem;
  color: #000000;
  text-align: center;
  word-break: break-all;
  white-space: normal;
}

.clear-filters {
  display: none;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 20px;
}

.input_fields_wrapper .file-tags {
  display: inline;
}
.input_fields_wrapper .item {
  border: 1.5px solid #2036a3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.input_fields_wrapper .item::after {
  content: "";
  display: table;
  clear: both;
}
.input_fields_wrapper .item.pending.uploaded .file-label {
  display: inline;
}
.input_fields_wrapper .item.pending.uploaded .file {
  display: inline;
  margin-left: 10px;
}
.input_fields_wrapper .item.done {
  background-color: #5ac784;
}
.input_fields_wrapper .select2.select2-container {
  display: inline-block;
  width: 444px !important;
  margin-left: 10px;
}
.input_fields_wrapper .select2.select2-container .select2-selection.select2-selection--multiple {
  width: 444px;
  display: inline-block;
}
.input_fields_wrapper .select2.select2-container .file-item-wrapper {
  margin-bottom: 10px;
}
.input_fields_wrapper .select2.select2-container .file-item-wrapper input[type=text] {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border: solid 1px #666666;
  height: 40px;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 0 5px;
  margin-left: 10px;
  width: 75%;
}
.input_fields_wrapper .select2.select2-container .file-item-wrapper .file-label,
.input_fields_wrapper .select2.select2-container .file-item-wrapper .tags-label {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "montserrat-bold";
}
.input_fields_wrapper .select2.select2-container * + .file-item-wrapper {
  margin-bottom: 20px;
}
.input_fields_wrapper .select2.select2-container .select-wrapper {
  float: right;
  margin-top: 10px;
}
.input_fields_wrapper .tags-wrapper {
  width: 100%;
}

.nav-tabs {
  border-bottom: none;
}

.tab-content {
  -webkit-border-radius: 0px 5px 5px 5px;
  -moz-border-radius: 0px 5px 5px 5px;
  -ms-border-radius: 0px 5px 5px 5px;
  -o-border-radius: 0px 5px 5px 5px;
  border-radius: 0px 5px 5px 5px;
  border-top-left-radius: 0px;
  border: solid 1px #2036a3;
}
.tab-content .tab-pane {
  padding: 40px;
}

* + footer {
  margin-top: 70px;
}

@media (max-width: 991px) {
  .input_fields_wrapper .select2.select2-container {
    display: block;
    width: 444px !important;
    margin: 10px 0 0 0 !important;
  }
  .filter-tags .files-container .files-wrapper .file-item-container {
    max-width: 33.3333%;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .progress-status {
    width: 444px;
  }
  #dropzone-form {
    padding: 126px 50px;
  }
  #dropzone-form .fake-btn {
    padding: 10px 10px;
  }
  #dropzone-form .file-msg {
    word-break: break-all;
    white-space: normal;
  }
  #create-folder .content button.btn {
    float: none;
    margin-left: 0;
    display: block;
  }
  .input_fields_wrapper .item input.keywords {
    display: block;
    margin: 10px 0 0 0 !important;
    width: 444px !important;
  }
  .input_fields_wrapper .select2.select2-container {
    display: block;
    width: 444px !important;
    margin: 10px 0 0 0 !important;
  }
  .filter-tags .files-container .files-wrapper .file-item-container {
    max-width: none;
    width: 100%;
    float: none;
  }
}
@media (max-width: 660px) {
  .global-tags-wrapper a.select-all {
    float: none;
    display: block;
    margin: 0 auto 20px auto;
    width: 100%;
  }
  .global-tags-wrapper .tags-title {
    text-align: center;
    margin: 0 auto 20px 0;
    display: block;
  }
  .global-tags-wrapper .global-tags {
    width: 100%;
    margin: 0 auto;
  }
  .global-tags-wrapper a#applyTags {
    width: 100% !important;
    height: 40px;
    margin: 20px auto;
    display: block;
  }
  .action-buttons a.clear-completed {
    width: 100% !important;
    height: 40px;
    margin: 20px auto;
    display: block;
  }
  .item .progress-status {
    width: inherit;
  }
  .item .file {
    display: block !important;
    margin-top: 10px !important;
    margin-left: 0 !important;
  }
  .input_fields_wrapper .select2.select2-container {
    display: block;
    width: 100% !important;
    margin: 10px 0 0 0 !important;
  }
  .input_fields_wrapper .select2.select2-container .select2-selection.select2-selection--multiple {
    width: 100%;
    display: block;
  }
  .input_fields_wrapper .item .file-item-wrapper input[type=text] {
    width: 100% !important;
  }
  .btnUploader-wrapper {
    display: block;
    margin: 0 auto;
    margin-top: 70px;
  }
  .btnUploader-wrapper a {
    margin: 0 auto !important;
  }
}
.media-manager {
  padding: 40px;
}
.media-manager .dt-search {
  display: table;
  float: right;
  padding: 20px 0 20px 20px;
}
.media-manager .dt-search .dt-search-icon {
  display: block;
  height: 20px;
  width: 20px;
  background-color: blue;
  float: right;
}
.media-manager .dt-search #files-table_filter.dataTables_filter {
  padding: 0;
  float: left;
}
.media-manager .breadcrums {
  float: left;
  display: block;
  width: 100%;
}
.media-manager .breadcrums a.btn {
  display: inline-block;
  font-family: "montserrat-medium";
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #000000;
}
.media-manager .breadcrums i {
  font-size: 16px;
  font-size: 1.6rem;
  color: #000000;
  vertical-align: middle;
}
.media-manager .multi-items-controls {
  float: none;
  margin: 10px 0px 10px auto;
  display: table;
}
.media-manager .multi-items-controls::after {
  content: "";
  display: table;
  clear: both;
}
.media-manager .multi-items-controls a {
  padding: 19px 20px 20px;
  font-family: "montserrat-medium";
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  float: left;
}
.media-manager .multi-items-controls #selectAll {
  background-image: none;
  border: none;
  background-color: #2036a3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.media-manager .multi-items-controls #selectAll .deselect {
  display: none;
}
.media-manager .multi-items-controls #selectAll.active {
  background-color: #15236b;
}
.media-manager .multi-items-controls #selectAll.active .select {
  display: none;
}
.media-manager .multi-items-controls #selectAll.active .deselect {
  display: inline-block;
}
.media-manager .multi-items-controls #downloadAll {
  background-image: none;
  border: none;
  background-color: #2036a3;
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.media-manager .multi-items-controls #deleteAll {
  background-image: none;
  border: none;
  background-color: #2036a3;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.media-manager .item.is-parent i {
  margin-right: 5px;
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.media-manager .first-row {
  padding: 10px 0;
  border: solid 1px #2036a3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
}
.media-manager .first-row::after {
  content: "";
  display: table;
  clear: both;
}
.media-manager .first-row .left a {
  font-family: "montserrat-medium";
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: #2036a3;
}
.media-manager .first-row .left a i {
  margin-right: 10px;
}
.media-manager .first-row .second-row .filter-by-tag {
  display: inline-block;
}
.media-manager .first-row .second-row .filter-by-tag label {
  font-family: "montserrat-medium";
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #000000;
}
.media-manager .first-row .second-row .filter-by-tag select {
  width: 160px;
  padding: 9px 0 9px 5px;
  border-radius: 5px;
  border: solid 1px #666666;
  font-family: "montserrat-medium";
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #000000;
  outline: none;
  /* Remove default arrow for Firefox */
  -moz-appearance: none;
  /* Remove default arrow for Chrome */
  -webkit-appearance: none;
  /* Remove default arrow for IE */
  /* SVG background image */
  background-image: url("./styles/svg/Chevrones.svg");
  background-size: 2.5rem;
  background-position: calc(100% - 5px) center;
  background-repeat: no-repeat;
}
.media-manager .first-row .second-row .filter-by-tag select::-ms-expand {
  display: none;
}
.media-manager .first-row .right form .form-group label {
  font-family: "montserrat-medium";
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: #000000;
}
.media-manager .first-row .right form .form-group input {
  display: inline-block;
  font-family: "montserrat-medium";
  width: 300px;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #9e9e9e;
  height: 40px;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.media-manager .first-row .right form .form-group button {
  float: none;
  vertical-align: baseline;
  display: inline-block;
  width: auto;
  padding: 9px 13px 9px 12px;
  background-color: #2036a3;
  background-image: none;
  margin-left: -4px;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.media-manager .item-actions a.btn {
  display: block;
  float: right;
  margin-right: 5px;
  background-color: inherit;
  background-image: none;
  border: none;
  color: black;
  box-shadow: none;
}
.media-manager .item-actions a.btn:nth-last-child(1) {
  margin-right: 0;
}
.media-manager .item-actions:after {
  content: "";
  clear: both;
  display: table;
  height: 0;
}
.media-manager #files-table_wrapper {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: solid 1px #2036a3;
}
.media-manager #files-table_wrapper .dataTables_length {
  padding: 20px 0 20px 20px;
}
.media-manager #files-table_wrapper .dataTables_length label {
  font-family: "montserrat-medium";
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #000000;
}
.media-manager #files-table_wrapper .dataTables_length label select {
  border-radius: 5px;
  border: solid 1px #666666;
  padding: 4px;
  text-align: left;
  /* Remove default arrow for Firefox */
  -moz-appearance: none;
  /* Remove default arrow for Chrome */
  -webkit-appearance: none;
  /* Remove default arrow for IE */
  /* SVG background image */
  width: 60px;
  height: auto;
  background-image: url(svg/Chevrones.svg);
  background-size: 2.5rem;
  background-position: calc(100% - 5px) center;
  background-repeat: no-repeat;
}
.media-manager #files-table_wrapper .dataTables_length label select::-ms-expand {
  display: none;
}
.media-manager #files-table_wrapper .dt-search {
  padding: 20px;
}
.media-manager #files-table_wrapper .dt-search .dt-search-icon {
  position: relative;
  width: 40px;
  height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -2px;
  background-color: #2036a3;
  cursor: pointer;
}
.media-manager #files-table_wrapper .dt-search .dt-search-icon:after {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: "FontAwesome";
  content: "\f002";
  color: white;
}
.media-manager #files-table_wrapper .dt-search .dataTables_filter {
  font-family: "montserrat-medium";
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  color: #9e9e9e;
}
.media-manager #files-table_wrapper .dt-search .dataTables_filter input {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: solid 1px #666666;
  padding: 9px 3px;
  width: 400px;
  outline: none;
}
.media-manager #files-table_wrapper #files-table thead tr {
  background-color: #2036a3;
  font-family: "montserrat-medium";
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #ffffff;
}
.media-manager #files-table_wrapper #files-table thead tr th {
  padding: 19px 20px;
}
.media-manager #files-table_wrapper #files-table thead tr th:nth-child(2) {
  background-image: none;
  cursor: default;
}
.media-manager #files-table_wrapper #files-table thead tr th:nth-child(3) {
  background-image: none;
  cursor: default;
  color: #2036a3;
}
.media-manager #files-table_wrapper #files-table tbody tr td {
  padding: 20px;
}
.media-manager #files-table_wrapper #files-table tbody tr td a.btn.select {
  padding: 0;
  margin-right: 20px;
  font-size: 18px;
  font-size: 1.8rem;
}
.media-manager #files-table_wrapper #files-table tbody tr td a.btn.select i {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
}
.media-manager #files-table_wrapper #files-table tbody tr td a.btn.folder {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 600;
  color: #000000;
  padding: 0;
  white-space: normal;
  word-wrap: break-word;
}
.media-manager #files-table_wrapper #files-table tbody tr.odd {
  background-color: #e4e6f1;
}
.media-manager #files-table_wrapper #files-table tbody tr.odd td {
  background-color: #e4e6f1;
}
.media-manager #files-table_wrapper #files-table tbody tr.even {
  background-color: #ffffff;
}
.media-manager #files-table_wrapper #files-table tbody tr.even td {
  background-color: #ffffff;
}
.media-manager #files-table_wrapper .dataTables_info {
  padding: 20px;
  font-family: "montserrat-medium";
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.media-manager #files-table_wrapper .dataTables_paginate {
  padding: 20px;
}
.media-manager #files-table_wrapper .dataTables_paginate .paginate_button {
  padding: 0;
}
.media-manager #files-table_wrapper .dataTables_paginate span {
  margin: 0 5px;
}
.media-manager #files-table_wrapper .dataTables_paginate span a.paginate_button {
  border: solid 1px #979797;
  background: none;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}
.media-manager #files-table_wrapper .dataTables_paginate span a.paginate_button.current {
  background-color: #e4e6f1;
}
.media-manager #files-table_wrapper .dataTables_paginate span a.paginate_button.current:hover {
  background-color: #e4e6f1;
}
.media-manager #files-table_wrapper .dataTables_paginate span a.paginate_button:hover {
  background-color: #ffffff;
  color: black !important;
}

body.is-root #file-uploader .media-manager .first-row .left {
  display: none;
}
body.is-root #file-uploader .media-manager #files-table_wrapper #files-table thead tr th:nth-child(2) {
  color: #2036a3;
}

@media (max-width: 991px) {
  .media-manager .first-row .right form .form-group input {
    width: 300px;
  }
  .media-manager #files-table_wrapper {
    overflow: auto;
  }
  .media-manager #files-table_wrapper .dt-search {
    padding: 0;
    float: none;
  }
  .media-manager #files-table_wrapper .dt-search .dt-search-icon {
    margin-top: 20px;
  }
  .media-manager #files-table_wrapper .dt-search #files-table_filter label input {
    width: 300px;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .media-manager {
    padding: 0;
  }
  .media-manager .multi-items-controls {
    float: none;
  }
  .media-manager .multi-items-controls:after {
    content: "";
    display: table;
    clear: both;
  }
  .media-manager .first-row .left a {
    word-break: break-all;
    white-space: normal;
  }
  .media-manager .first-row .right form .form-group input {
    width: 140px;
  }
  .media-manager #files-table_wrapper {
    overflow: auto;
  }
  .media-manager #files-table_wrapper .dt-search {
    padding: 0 20px 0 0;
    float: right;
  }
  .media-manager #files-table_wrapper .dt-search #files-table_filter label input {
    width: 225px;
    margin-top: 20px;
  }
}
@media (max-width: 640px) {
  .media-manager #files-table_wrapper .dt-search {
    padding: 0 20px 0 0;
    float: none;
    display: table;
    margin: 0 auto;
  }
  .media-manager #files-table_wrapper .dt-search #files-table_filter label input {
    width: 160px;
    margin-top: 20px;
  }
  .media-manager #files-table_wrapper .dt-search .dt-search-icon {
    margin: 27px 0 0 0;
  }
}